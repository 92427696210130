.navbar {
  position: fixed;
  z-index: 5;
  width: 100%;
  padding: 25px 0;
  transition: all 0.3s ease;
}

.navbar.sticky {
  padding: 15px 0px;
  background: #fff;
}

.navbar.sticky .menuList li a {
  color: #272727;
  color: var(--secondary);
}

.navbar.sticky .menuList li:hover a,
.navbar.sticky .menuList li a.active {
  color: var(--primary);
  font-weight: bold;
}

.navbar .content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.content {
  max-width: 1250px;
  margin: auto;
  padding: 0px 30px;
}

.navbar .menuList {
  display: inline-flex;
}

.navbar .logo {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.navbar .logo .logoImage {
  height: 50px;
  width: 50px;
  object-fit: cover;
  border-radius: 50%;
}

.navbar .logo a {
  color: var(--secondary);
  font-size: 30px;
  font-weight: bold;
  text-decoration: none;
}

.menuList li {
  list-style: none;
}

.menuList li a {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  text-decoration: none;
  margin-left: 25px;
  transition: all 0.3s ease;
}

.menuList li a:hover {
  color: var(--primary);
}

.navbar.navbar.sticky .icon {
  color: var(--secondary);
}

.icon {
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  display: none;
}

.icon.cancelBtn {
  position: absolute;
  right: 30px;
  top: 20px;
}

@media (max-width: 868px) {
  .icon {
    display: block;
  }
  .icon.hide {
    display: none;
  }
  .navbar .menuList {
    position: fixed;
    top: 0;
    left: -100%;
    height: 100vh;
    width: 100%;
    max-width: 400px;
    background: #222;
    display: block;
    padding: 40px 0;
    text-align: center;
    transition: all 0.3s ease;
  }

  .navbar .logo a {
    font-size: 20px;
  }

  .navbar .menuList.active {
    left: 0%;
  }

  .navbar .menuList li {
    margin-top: 45px;
  }

  .navbar .menuList li a {
    font-size: 23px;
  }

  .navbar.sticky .menuList li a {
    color: #fff;
  }
}
