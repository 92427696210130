.section {
  min-height: 100vh;
  padding: 5rem 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  background: url(../../../assets/webp/abstract.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  /* background-attachment: fixed; */
  position: relative;
}

.title {
  font-size: 3.5rem;
  font-weight: bold;
  color: #fff;
}

.subtitle {
  margin-top: 1rem;
  font-weight: normal;
  color: #fff;
  font-size: 1rem;
}

.illustration {
  margin-top: 50px;
  height: 500px;
  width: 100%;
}
.illustration img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.features {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  gap: 7rem;
  width: 100%;
  margin-top: 50px;
}

.feature {
  text-align: center;
}

.feature .icon {
  color: #ddd;
  font-size: 50px;
  font-weight: lighter;
}

.feature .title {
  color: #fff;
  font-size: 26px;
  font-weight: 400;
}

.feature .subtitle {
  color: #ccc;
  font-size: 18px;
  font-weight: lighter;
}

@media (max-width: 868px) {
  .features {
    grid-template-columns: repeat(1, 1fr);
    gap: 4rem;
  }

  .feature .title {
    font-size: 23px;
  }

  .feature .subtitle {
    font-size: 15px;
  }

  .title {
    font-size: 2rem;
  }

  .subtitle {
    font-size: 1rem;
  }
}
