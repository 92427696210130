.banner {
  height: 100vh;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  gap: 2rem;
  background: url(../../../assets/landing.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  /* background-attachment: fixed; */
  position: relative;
}

.banner .left {
  padding-left: 130px;
  padding-top: 2rem;
}

.banner .left .title {
  font-size: 35px;
  font-weight: bold;
  line-height: 1.7;
  background: linear-gradient(to bottom, var(--secondary), var(--landing));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.banner .right {
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttonsContainer {
  margin-top: 2rem;
  display: flex;
  align-items: center;
}

.buttonsContainer .iconButton {
  height: 50px;
  width: 50px;
  margin-left: 2rem;
}

.buttonsContainer .iconButton img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.getButton {
  padding: 1rem;
  width: 200px;
  text-align: center;
  border-radius: 12px;
  cursor: pointer;
  border: 2px solid var(--secondary);
  color: var(--landing);
  background-color: #fff;
  font-weight: 500;
  transition: all 0.3s ease;
}

.getButton:hover {
  color: #fff;
  font-weight: bolder;
  background-color: var(--secondary);
}

.quote {
  color: var(--secondary);
  margin-top: 2rem;
}

.quote .highlight {
  color: var(--landing);
  font-weight: 500;
}

.quote .highlight:hover {
  text-decoration: underline;
}

.mock {
  position: absolute;
  bottom: 0px;
  right: 30px;
  height: 90vh;
}

.mock img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 868px) {
  .mock {
    position: absolute;
    height: 35vh;
  }

  .mock img {
    object-fit: contain;
  }

  .banner {
    display: block;
  }
  .banner .left {
    padding-left: 30px;
    padding-top: 10rem;
  }
  .banner .left .title {
    font-size: 30px;
    background: linear-gradient(var(--secondary), var(--secondary));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .quote .highlight {
    font-weight: 500;
    color: #fff;
  }

  .buttonsContainer .iconButton {
    margin-left: 1rem;
  }
}

@media (max-width: 390px) {
  .mock {
    height: 30vh;
    width: 100%;
  }
  .mock img {
    object-fit: contain;
  }
}
